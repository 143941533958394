<template src="./EditAdvertising.html">

</template>

<script>
import UploadImageModal from "@/components/UploadImageModal/UploadImageModal";
import coursesService from "@/services/Courses";
import advertisingService from "@/services/Advertising";

export default {
  name: "EditAdvertising",
  components: {
    UploadImageModal
  },
  data() {
    return {
      file: null,
      isActive: false,
      infoFile: null,
      title: null,
      description: null,
      type: null,
      events: [],
      advertisingType: null,
      elementId: 'elementId',
      imageDescription: null,
      data: {},
      imageLink: null
    }
  },
  methods: {
    async updateAdvertising() {
      try {
        if( this.infoFile ) {
          this.imageLink = await coursesService.uploadFile('imagesAdvertising', 'imagesAdvertising', this.infoFile.name, this.infoFile.file);
        }
        const info = {
          title: this.data.title,
          description: this.data.description,
          type: this.data.type,
          elementId: this.data.elementId,
          image: this.infoFile? this.imageLink: this.data.image,
          imageDescription: this.infoFile? this.infoFile.description : this.data.imageDescription,
        }
        await advertisingService.updateAdvertising(this.$route.params.id, info);
        await this.$router.push({name: "ViewAllAdvertising"});
        this.$buefy.toast.open({
          duration: 5000,
          message: "Publicidad actualizada correctamente",
          position: "is-bottom",
          type: "is-success"
        });
      } catch (e) {
        console.log('e', e);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Error actualizando publicidad!",
          type: "is-error",
        });
      }
    },
    async getOptionsToShow() {
      try {
        if (this.data.type === 'event') this.events = await advertisingService.getLastEvents();
        else if (this.data.type === 'announcement') this.events = (await advertisingService.getAnnouncementList()).jobOffers;
      } catch (e) {
        console.log('e', e);
      }
    },
    async getAdvertisingInfo() {
      try {
        this.data = await advertisingService.getAdvertisingInfo(this.$route.params.id);
        await this.getOptionsToShow();
      } catch (e) {
        console.log('e', e);
      }
    }
  },
  mounted() {
    this.getAdvertisingInfo();

  }
}
</script>

<style scoped lang="scss" src="./EditAdvertising.scss">

</style>
