import {nodeInstance, phpInstance} from "@/plugins/axios";
import store from "@/store";

const advertisingService = {};

advertisingService.getAllAdvertising = () => {
  return nodeInstance.get('/api/publicity');
}

advertisingService.validateEventCode = (id, code) => {
  return nodeInstance.get(`/api/event/code/validation`, {params: { id, code } });
}

advertisingService.editEvent = (id, obj) => {
  return nodeInstance.put(`/api/event/${id}`, obj);
}

advertisingService.deleteEvent = (id) => {
  return nodeInstance.delete(`/api/event/${id}`);
}
advertisingService.deleteAdvertising = (id) => {
  return nodeInstance.delete(`/api/publicity/${id}`);
}

advertisingService.getMyCreatedEvents = () => {
  return nodeInstance.get('/api/event');
}

advertisingService.getLastEvents = () => {
  const params = {
    skip: 0,
    take: 15
  };
  return nodeInstance.get('/api/event/myUserModerator', {params});
}

advertisingService.getAnnouncementList = () => {
  const params = {
    skip: 0,
    take: 15
  };
  return nodeInstance.get(`/api/owner/jobOffer`, {params});
}
advertisingService.getAdvertisingInfo = (id) => {
  return nodeInstance.get(`/api/publicity/${id}`);
}

advertisingService.getTodaysEvents = () => {
  return nodeInstance.get('/api/event/current');
}

advertisingService.registerForAnEvent = (eventId) => {
  return nodeInstance.post(`/api/event/${eventId}/register`);
}

advertisingService.createAdvertising = (info) => {
  return nodeInstance.post(`/api/publicity`, info);
}

advertisingService.updateAdvertising = (id, info) => {
  return nodeInstance.patch(`/api/publicity/${id}`, info);
}

advertisingService.getRecommendedEvents = () => {
  return nodeInstance.get('/api/event/recommended');
}

advertisingService.getSearchEvents = (word) => {
  const params = {
    word
  };
  return nodeInstance.get(`/api/event/search`, {params});
}

advertisingService.getModerators = search => {
  return phpInstance.post(`/connection/searchUser/${search}`);
}

advertisingService.getAllEvent = () => {
  const token = store.getters.getToken;
  const params = {
    token
  };
  return nodeInstance.get(`/api/event/events`, {params});
}

advertisingService.getTodayEvent = () => {
  const token = store.getters.getToken;
  const params = {
    token
  };
  return nodeInstance.get(`/api/event/searchEventsDay`, {params});
}

advertisingService.getAttendedEvent = () => {
  return nodeInstance.get(`/api/event/searchEventsAssisted`);
}

advertisingService.getLastEvent = () => {
  return nodeInstance.get(`/api/event/searchEventsLast`);
}

advertisingService.getInterestingEvent = () => {
  return nodeInstance.get(`/api/event/searchEventsInterest`);
}

advertisingService.getEvent = eventId => {
  return nodeInstance.get(`/api/event/${eventId}`);
}

export default advertisingService;
